import React from 'react';
import { Box, Button, Flex, Heading, Stack } from '@chakra-ui/react';

import { Card, MajorCardContents } from './Card';
import { addIcon, removeIcon } from './functions/utils';

import { useStoreState } from 'pullstate';
import { GameStore } from './stores/gameStore';

//const iconsData = require('../game-content/icons.json');

export function IconCard(props) {
  const { icon } = props;
  return (
    <Card maxWidth="50%">
      <MajorCardContents
        name={icon.icon}
        cardName={icon.name}
        img={icon.img}
        coins={icon.coins}
        remove={removeIcon}
        description={icon.description}
      />
    </Card>
  );
}

export function Icons() {
  const icons = useStoreState(GameStore, (s) => s.icons);

  return (
    <Box>
      <Heading>Icons</Heading>
      <Stack>
        <Stack isInline spacing={2}>
          {[...icons.values()].map((icon) => (
            <IconCard key={icon.name} icon={icon} />
          ))}
        </Stack>
        <Flex flex={1} justify="center">
          <Button colorScheme="teal" onClick={addIcon}>
            + Icon
          </Button>
        </Flex>
      </Stack>
    </Box>
  );
}
