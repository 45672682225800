import { Store } from 'pullstate';
import { enableMapSet } from 'immer';
import { db } from './firebase';

import { updateChannelCharacter } from '../functions/utils';

enableMapSet();

function getInitialGameState() {
  return {
    royals: new Map(),
    icons: new Map(),
    arcana: new Map(),
    currentDraw: [], // Might contain multiple draws (silver or twist)
    usedCards: new Set(),
  };
}

function deserializeGameState(data) {
  var store = {};
  for (const [key, value] of Object.entries(data)) {
    if (key === 'royals' || key === 'icons' || key === 'arcana') {
      store[key] = new Map(Object.entries(value));
    } else if (key === 'usedCards') {
      store[key] = new Set([...value]);
    } else {
      store[key] = value;
    }
  }
  return store;
}

function serializeGameState(data) {
  var store = {};
  for (const [key, value] of Object.entries(data)) {
    if (key === 'royals' || key === 'icons' || key === 'arcana') {
      store[key] = Object.fromEntries(value);
    } else if (key === 'usedCards') {
      store[key] = Array.from(value);
    } else {
      store[key] = value;
    }
  }
  return store;
}

export const GameStore = new Store(getInitialGameState());
let gameRef = null;
let unsubscribeGame = () => null;

/*
function getGameRef(channelName) {
  if (!channelName) {
    return;
  }
  // check if channel name exists in channel list
  db.ref('channels')
    .orderByChild('name')
    .equalTo(channelName)
    .once('value')
    .then(function (snapshot) {
      if (snapshot.exists()) {
        snapshot.forEach(function (childSnapshot) {
          console.log(db.ref(childSnapshot.ref.key));
          return db.ref(childSnapshot.ref.key);
        });
      } else {
        console.log('Invalid game name');
        return;
      }
    });
}
*/

export function subscribeGame(channelName) {
  unsubscribeGame();

  if (!channelName) {
    return;
  }

  // check if channel name exists in channel list
  db.ref('channels')
    .orderByChild('name')
    .equalTo(channelName)
    .once('value')
    .then(function (snapshot) {
      if (snapshot.exists()) {
        snapshot.forEach(function (childSnapshot) {
          console.log(`Subscribing to game ${childSnapshot.key}`);
          gameRef = db.ref(childSnapshot.key);
        });
        // subscribe to firebase game store
        gameRef.on('value', function (snapshot) {
          if (snapshot.exists()) {
            GameStore.update((s) => {
              Object.assign(s, deserializeGameState(snapshot.val()));
            });
          }
        });
        unsubscribeGame = () => gameRef.off;
      } else {
        console.log('Invalid game name');
        return;
      }
    });
}

export function resetGameState() {
  GameStore.update((s) => (s = getInitialGameState()));
}

GameStore.createReaction(
  (s) => s,
  (s) => {
    if (!gameRef) {
      return;
    }
    gameRef.update(serializeGameState(s));
  }
);
