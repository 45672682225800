import React, { useState } from 'react';
import {
  Box,
  Button,
  CloseButton,
  Editable,
  EditableInput,
  EditablePreview,
  Flex,
  Heading,
  Image,
  List,
  ListItem,
  Stack,
  Text,
} from '@chakra-ui/react';
import { TiArrowShuffle } from 'react-icons/ti';

import { Card } from './Card';
import { Coins } from './Coins';
import { addRoyal, removeRoyal } from './functions/utils';

import { useStoreState } from 'pullstate';
import { GameStore } from './stores/gameStore';

//const royalsData = require('../game-content/royals.json');

export function Twist() {
  return <Box as={TiArrowShuffle} d="inline" boxSize="32px" />;
}

function EditableProp(props) {
  const { royalName, propName, placeholder } = props;
  const storeProp = useStoreState(
    GameStore,
    (s) => s.royals.get(royalName) && s.royals.get(royalName)[propName]
  );
  const [tempProp, setTempProp] = useState(null);

  return (
    <Editable
      value={tempProp != null ? tempProp : storeProp}
      placeholder={placeholder}
      onChange={(name) => {
        setTempProp(name);
      }}
      onSubmit={() => {
        if (tempProp) {
          GameStore.update((s) => {
            s.royals.get(royalName)[propName] = tempProp;
          });
        }
        setTempProp(null);
      }}
    >
      <EditablePreview />
      <EditableInput />
    </Editable>
  );
}

export function RoyalCard(props) {
  const royal = useStoreState(GameStore, (s) => s.royals.get(props.royal));

  return (
    <Card maxWidth="50%">
      <Stack isInline>
        <EditableProp
          royalName={royal.name}
          propName="charName"
          placeholder="Click to add name..."
        />
        <Flex flex={1} />
        <EditableProp
          royalName={royal.name}
          propName="charPronouns"
          placeholder="Click to add pronouns..."
        />
        <Flex flex={1} />
        <CloseButton onClick={() => removeRoyal(royal.name)} />
      </Stack>
      <Heading size="lg">{royal.name}</Heading>
      <Image
        src={`/images/tarot/${royal.img}.jpg`}
        w="50%"
        float="left"
        m={2}
      />
      <Text as="i" m={2}>
        {royal.description}
      </Text>
      <Heading size="md" mt={3}>
        Facets
      </Heading>
      <List styleType="disc">
        {Object.keys(royal.facets).map((facetType) =>
          royal.facets[facetType].map((facet) => <ListItem>{facet}</ListItem>)
        )}
      </List>
      <Stack isInline m={2}>
        <Coins value={royal.coins || 0} />
        <Flex flex={1} />
        {royal.twist && <Twist />}
      </Stack>
      <Stack isInline m={5} w="80%">
        <EditableProp
          royalName={royal.name}
          propName="playerName"
          placeholder="Click to add player name..."
        />
        <Flex flex={1} />
        <EditableProp
          royalName={royal.name}
          propName="playerPronouns"
          placeholder="Click to add player pronouns..."
        />
      </Stack>
    </Card>
  );
}

export function Royals() {
  const royals = useStoreState(GameStore, (s) => s.royals);

  return (
    <Box>
      <Heading>Royals</Heading>
      <Stack>
        <Stack isInline spacing={2}>
          {[...royals.values()].map((royal) => (
            <RoyalCard key={royal.name} royal={royal.name} />
          ))}
        </Stack>
        <Flex flex={1} justify="center">
          <Button colorScheme="teal" onClick={addRoyal}>
            + Royal
          </Button>
        </Flex>
      </Stack>
    </Box>
  );
}
