import React from 'react';
import {
  Box,
  CloseButton,
  Flex,
  Heading,
  Image,
  SimpleGrid,
  Stack,
  Text,
  Textarea,
} from '@chakra-ui/react';
import { Coins } from './Coins';

export function MajorCardContents(props) {
  const {
    name,
    cardName,
    img,
    remove,
    description,
    notes,
    coins,
    notesOnChange,
  } = props;
  let [tempNotes, setTempNotes] = React.useState(null);

  let handleInputChange = (e) => {
    let inputValue = e.target.value;
    setTempNotes(inputValue);
  };
  return (
    <>
      <Stack isInline>
        <Heading size="lg">{name}</Heading>
        <Flex flex={1} />
        <Coins value={coins} mr={2} />
        <CloseButton onClick={() => remove(cardName)} />
      </Stack>
      <SimpleGrid columns={2}>
        <Image src={`/images/tarot/${img}.jpg`} float="left" m={2} w="100%" />
        <Stack m={5}>
          <Text as="i">{description}</Text>
          {false && (
            <Textarea
              h="100%"
              minH="150px"
              value={tempNotes || notes}
              onChange={handleInputChange}
              placeholder="Notes..."
            />
          )}
        </Stack>
      </SimpleGrid>
    </>
  );
}

export function Card(props) {
  const { children, ...rest } = props;
  return (
    <Box
      rounded="lg"
      m={2}
      p={2}
      boxShadow="0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)"
      bg="white"
      {...rest}
    >
      {children}
    </Box>
  );
}
