import React, { useState } from 'react';
import { Button, ChakraProvider, Input, Stack } from '@chakra-ui/react';
import { Result } from './Result';
import { Royals } from './Royals';
import { Icons } from './Icons';
import { Arcana } from './Arcane';

import { subscribeGame } from './stores/gameStore';

function Channel(props) {
  const { channelName, setChannelName } = props;
  const [tempChannelName, setTempChannelName] = useState(channelName);

  function handleSubmit(e) {
    e.preventDefault();
    setChannelName(tempChannelName.replace(/\W/g, ''));
    subscribeGame(tempChannelName);
  }

  return (
    <Stack align="center">
      <form onSubmit={handleSubmit}>
        <Stack maxWidth="225px" my="20px">
          <Input
            type="text"
            style={{ textAlign: 'center' }}
            name="channel"
            rounded="lg"
            placeholder="Channel Name"
            value={tempChannelName || ''}
            onChange={(e) => setTempChannelName(e.target.value)}
          />
          <Button type="submit" colorScheme="teal">
            Enter
          </Button>
        </Stack>
      </form>
    </Stack>
  );
}

function MainView() {
  return (
    <div className="App" style={{ background: 'rgb(214 94 94)' }}>
      <Stack>
        <Result />
        <Royals />
        <Icons />
        <Arcana />
      </Stack>
    </div>
  );
}

function App() {
  const [channelName, setChannelName] = useState('');

  return (
    <ChakraProvider>
      {channelName === '10Oct' ? (
        <MainView />
      ) : (
        <Channel channel={channelName} setChannelName={setChannelName} />
      )}
    </ChakraProvider>
  );
}
export default App;
