/* DO NOT COMMIT THIS FILE WITH CONFIG DATA */

const devConfig = {
  apiKey: 'AIzaSyCNekaCTiC-Rpsgj78398jKCQ1vsGGRdkc',
  authDomain: 'cloud-games-c92f6.firebaseapp.com',
  databaseURL: 'https://cloud-games-tarot-cloud.firebaseio.com/',
  projectId: 'cloud-games-c92f6',
  storageBucket: 'cloud-games-c92f6.appspot.com',
  messagingSenderId: '63046152121',
  appId: '1:63046152121:web:a762f0be0c378746c75068',
  measurementId: 'G-YCB2ZG77NZ',
};

const prodConfig = {
  apiKey: 'AIzaSyASbMrLd7gORoeUqT8ob9a2sWnZUs0o6DQ',
  authDomain: 'tarot-cloud.firebaseapp.com',
  databaseURL: 'https://tarot-cloud.firebaseio.com',
  projectId: 'tarot-cloud',
  storageBucket: 'tarot-cloud.appspot.com',
  messagingSenderId: '151305878557',
  appId: '1:151305878557:web:3cb114e8367e93a6eb5af4',
};

export const firebaseConfig =
  process.env.NODE_ENV === 'production' ? prodConfig : devConfig;
