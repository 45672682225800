import React from 'react';
import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Stack,
  Text,
} from '@chakra-ui/react';

import { Card } from './Card';
import { getResult } from './functions/utils';

import { useStoreState } from 'pullstate';
import { GameStore } from './stores/gameStore';

const results = require('./game-content/results.json');

function getResultData(number) {
  return results.results[results.resultMap[number]];
}

export function ResultCard(props) {
  const { result } = props;
  const resultData = getResultData(result.number);

  return (
    <Card maxWidth="20%">
      <Stack alignItems="center">
        <Image src={`/images/tarot/${result.img}.jpg`} />
        <Heading size="md">{result.title}</Heading>
        <Text>
          <i>{resultData.name}</i> {resultData.description}
        </Text>
      </Stack>
    </Card>
  );
}

export function ResultCards() {
  const results = useStoreState(GameStore, (s) => s.currentDraw);

  return (
    <Box>
      {results && (
        <Stack isInline spacing={2} justifyContent="center">
          {[...results.values()].map((result) => (
            <ResultCard key={result.img} result={result} />
          ))}
        </Stack>
      )}
    </Box>
  );
}

export function Result() {
  return (
    <Box>
      <Stack>
        <ResultCards />
        <Flex flex={1} justify="center">
          <Button colorScheme="teal" onClick={getResult}>
            Draw
          </Button>
        </Flex>
      </Stack>
    </Box>
  );
}
