import React from 'react';
import { Box, Button, Flex, Heading, Stack } from '@chakra-ui/react';

import { Card, MajorCardContents } from './Card';
import { addArcane, removeArcane } from './functions/utils';

import { useStoreState } from 'pullstate';
import { GameStore } from './stores/gameStore';

//const arcaneData = require('../game-content/arcane.json');

export function ArcaneCard(props) {
  const { arcane } = props;

  return (
    <Card maxWidth="50%">
      <MajorCardContents
        name={arcane.name}
        cardName={arcane.name}
        img={arcane.img}
        coins={arcane.coins}
        remove={removeArcane}
        description={arcane.description}
      />
    </Card>
  );
}

export function Arcana() {
  const arcana = useStoreState(GameStore, (s) => s.arcana);

  return (
    <Box>
      <Heading>Arcana</Heading>
      <Stack>
        <Stack isInline spacing={2} justifyContent="center">
          {[...arcana.values()].map((arcane) => (
            <ArcaneCard key={arcane.name} arcane={arcane} />
          ))}
        </Stack>
        <Flex flex={1} justify="center">
          <Button colorScheme="teal" onClick={addArcane}>
            + Arcane
          </Button>
        </Flex>
      </Stack>
    </Box>
  );
}
