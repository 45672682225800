import React, { useState } from 'react';
import { Box, Flex, IconButton } from '@chakra-ui/react';
import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import { RiCopperCoinLine } from 'react-icons/ri';

export function Coins(props) {
  const { value, ...rest } = props;
  var [max, setMax] = useState(value || 0);
  const coins = [];
  for (let i = 0; i < max; i++) {
    coins.push(<Box as={RiCopperCoinLine} key={i} d="inline" size="32px" />);
  }

  return (
    <Flex align="center" m="3px 3px 8px">
      <IconButton
        size="sm"
        icon={<MinusIcon />}
        onClick={() => setMax(max - 1)}
        isDisabled={max === 0}
      />
      <Box {...rest}>{coins}</Box>
      <IconButton
        size="sm"
        icon={<AddIcon />}
        colorScheme="teal"
        onClick={() => setMax(max + 1)}
      />
    </Flex>
  );
}
