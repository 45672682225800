import { GameStore } from '../stores/gameStore';

const royalsData = require('../game-content/royals.json');
const iconsData = require('../game-content/icons.json');
const arcaneData = require('../game-content/arcane.json');
const deck = require('../game-content/deck.json');

// https://stackoverflow.com/a/15106541
function randomProperty(obj) {
  var keys = Object.keys(obj);
  return obj[keys[(keys.length * Math.random()) << 0]];
}

const getCard = {
  royals: getRoyal,
  icons: getIcon,
  arcana: getArcane,
};

function addCard(cardType) {
  const cards = GameStore.currentState[cardType];
  if (cards.size >= 20) {
    return;
  }
  var newCard = getCard[cardType]();
  while (
    GameStore.currentState.royals.has(newCard) ||
    GameStore.currentState.icons.has(newCard) ||
    GameStore.currentState.arcana.has(newCard)
  ) {
    newCard = getCard[cardType]();
  }
  // add some defaults
  newCard.coins = 0;
  newCard.charName = 'character name';
  newCard.charPronouns = 'character pronouns';
  newCard.playerName = 'player name';
  newCard.playerPronouns = 'player pronouns';
  GameStore.update((s) => {
    s[cardType] = new Map([...cards, [newCard.name, newCard]]);
  });
}

function removeCard(cardType, cardName) {
  console.log('Removing ' + cardType + ' ' + cardName);
  const cards = new Map([...GameStore.currentState[cardType]]);
  cards.delete(cardName);
  GameStore.update((s) => {
    s[cardType] = cards;
  });
}

export function getRoyal() {
  return randomProperty(royalsData.royals);
}

export function addRoyal() {
  addCard('royals');
}

export function removeRoyal(royalName) {
  removeCard('royals', royalName);
}

export function getIcon() {
  return randomProperty(iconsData);
}

export function addIcon() {
  addCard('icons');
}

export function removeIcon(iconName) {
  removeCard('icons', iconName);
}

export function getArcane() {
  return randomProperty(arcaneData);
}

export function addArcane() {
  addCard('arcana');
}

export function removeArcane(arcaneName) {
  removeCard('arcana', arcaneName);
}

function getImgName(suit, number) {
  var num = `${number}`.padStart(2, 0);
  return suit ? `${suit}-${num}` : `${num}`;
}

function getCardTitle(suit, number) {
  return `${capitalize(deck.titles[number])} of ${capitalize(suit)}`;
}

export function drawResult() {
  return {
    suit: deck.suits[Math.floor(Math.random() * deck.suits.length)],
    number: deck.numbers[Math.floor(Math.random() * deck.numbers.length)],
  };
}

export function getResult() {
  const cards = GameStore.currentState.usedCards;
  var newCard = {};
  var imgName = '';
  do {
    newCard = drawResult();
    imgName = getImgName(newCard.suit, newCard.number);
  } while (cards && cards.has(imgName));
  GameStore.update((s) => {
    s.currentDraw = [
      {
        ...newCard,
        img: imgName,
        title: getCardTitle(newCard.suit, newCard.number),
      },
    ];
    var used = new Set(cards).add(imgName);
    s.usedCards = used;
  });
}

export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
